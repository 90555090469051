"use client";

import Link from "next/link";
import React from "react";
export const Logo = () => {
  // const handleLogoClick = (e: React.MouseEvent) => {
  //   e.preventDefault();
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };
  return <Link href="/"
  // onClick={handleLogoClick}
  className="relative z-20 mr-4 flex items-center space-x-2  px-2 py-1 text-sm  font-normal text-black" data-sentry-element="Link" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
      <div className="h-5 w-6 rounded-bl-sm rounded-br-lg rounded-tl-lg rounded-tr-sm bg-black dark:bg-white" />
      <span className="font-medium text-black dark:text-white">
        Viral Launch
      </span>
    </Link>;
};