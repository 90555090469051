"use client";

import * as React from "react";
import { useTheme } from "next-themes";
import { MoonIcon } from "lucide-react";
import { SunMedium } from "lucide-react";
import { motion } from "framer-motion";
export function ModeToggle() {
  const {
    theme,
    setTheme
  } = useTheme();
  const [isClient, setIsClient] = React.useState(false);
  React.useEffect(() => {
    setIsClient(true);
  }, []);
  return isClient && <button onClick={() => {
    theme === "dark" ? setTheme("light") : setTheme("dark");
  }} className="flex h-10 w-10 items-center justify-center overflow-hidden rounded-lg outline-none hover:bg-gray-50 focus:outline-none focus:ring-0 active:outline-none active:ring-0 dark:hover:bg-white/[0.1]">
        {theme === "light" && <motion.div key={theme} initial={{
      x: 40,
      opacity: 0
    }} animate={{
      x: 0,
      opacity: 1
    }} transition={{
      duration: 0.3,
      ease: "easeOut"
    }}>
            <SunMedium className="h-4 w-4 flex-shrink-0  text-neutral-700 dark:text-neutral-500" />
          </motion.div>}

        {theme === "dark" && <motion.div key={theme} initial={{
      x: 40,
      opacity: 0
    }} animate={{
      x: 0,
      opacity: 1
    }} transition={{
      ease: "easeOut",
      duration: 0.3
    }}>
            <MoonIcon className="h-4 w-4   flex-shrink-0  text-neutral-700 dark:text-neutral-500" />
          </motion.div>}

        <span className="sr-only">Toggle theme</span>
      </button>;
}